import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Gadget Guru Repairs
			</title>
			<meta name={"description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:title"} content={"Gadget Guru Repairs"} />
			<meta property={"og:description"} content={"Your reliable partner in equipment repair"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1" />
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Everything you need for reliable performance of your equipment
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					At "Gadget Guru Repairs" we offer a wide range of repair and maintenance services for various types of equipment. Our team of professionals is always ready to help you solve any problems related to your devices.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12:51:47.451Z"
					width="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="100%"
					border-radius="30px"
					box-shadow="--xl"
					srcSet="https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a246642fc7070018ba356d/images/shutterstock_1187984653.jpg?v=2024-07-25T12%3A51%3A47.451Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Our Services
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							- Computer and Laptop Repair: We offer professional computer and laptop repair services, including diagnostics, part replacement, software adjustments, and virus removal.
							<br />
							<br />
							- Smartphone and Tablet Repair: Our specialists will quickly and efficiently repair your smartphone or tablet, replace screens, batteries, and fix software issues.
							<br />
							<br />
							- Home Appliance Repair: We provide repair services for washing machines, refrigerators, microwaves, and other home appliances with a quality guarantee.
							<br />
							<br />
							- Network Device Maintenance: Professional configuration and maintenance of routers, modems, and other network devices to ensure stable network performance.
							<br />
							<br />
							- Software Installation: We provide services for installing and configuring operating systems, programs, and drivers to ensure optimal performance of your devices.
							<br />
							<br />
							- Technical Support: Our specialists are always ready to offer advice and technical support, helping with any questions related to your equipment.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66a246642fc7070018ba356d/images/iStock-933640670-1.jpg?v=2024-07-25T12:51:47.449Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Your comfort and convenience are our priority
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Entrust us with your equipment, and we will take care of the rest. "Gadget Guru Repairs" is your reliable partner in the world of home appliances. Contact us today and experience the high quality of our services.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});